import { FaStore, FaHandshake } from "react-icons/fa";
import { GiCardExchange } from "react-icons/gi";
import { MdLocalOffer } from "react-icons/md";

export const links_usernft = [
  {
    id: 1,
    title: "Marketplace",
    icon: FaStore,
    path: "/marketplace",
  },
  {
    id: 2,
    title: "My Offers",
    icon: MdLocalOffer,
    path: "/my-offers",
  },
  {
    id: 3,
    title: "My NFTs",
    icon: GiCardExchange,
    path: "/my-nfts",
  },
  {
    id: 4,
    title: "My Deals",
    icon: FaHandshake,
    path: "/my-deals",
  },
];
