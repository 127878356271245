import AWS from "aws-sdk";

// Configure AWS SDK to connect to Filebase
const filebaseAccessKey = process.env.REACT_APP_FILEBASE_ACCESS_KEY; // Replace with your Access Key
const filebaseSecretKey = process.env.REACT_APP_FILEBASE_SECRET_KEY; // Replace with your Secret Key
const bucketName = process.env.REACT_APP_FILEBASE_BUCKET_NAME;
const region = process.env.REACT_APP_FILEBASE_BUCKET_REGION; // Filebase uses us-east-1 region for S3 compatibility

// Initialize S3 client with Filebase credentials
const s3 = new AWS.S3({
  endpoint: process.env.REACT_APP_FILEBASE_ENDPOINT,
  accessKeyId: filebaseAccessKey,
  secretAccessKey: filebaseSecretKey,
  region,
  signatureVersion: "v4",
});

const uploadToFileBaseAndReturnUrl = async (params) => {
  if (!params) throw new Error("Invalid params");

  return new Promise((resolve, reject) => {
    const request = s3.putObject(params);

    let responseObj = {};

    request.on("httpHeaders", (statusCode, headers) => {
      const cidReceived = headers["x-amz-meta-cid"];
      if (!cidReceived) {
        return reject(
          new Error("Error uploading to Filebase: CID not received")
        );
      }

      responseObj.cid = cidReceived;
      responseObj.url = `https://ipfs.filebase.io/ipfs/${cidReceived}`;
    });

    request.on("error", (error) => {
      reject(error);
    });

    request.send((err) => {
      if (err) {
        return reject(err);
      }
      resolve(responseObj);
    });
  });
};

// const uploadToFileBaseAndReturnUrl = async (params) => {
//   if (!params) throw new Error("Invalid params");
//   try {
//     let responseObj = {};
//     const request = s3.putObject(params);
//     request.on("httpHeaders", (statusCode, headers) => {
//       const cidReceived = headers["x-amz-meta-cid"];
//       if (!cidReceived) {
//         throw new Error("Error uploading to Filebase: CID not received");
//       }
//       responseObj.cid = headers["x-amz-meta-cid"];
//       responseObj.url = `https://ipfs.filebase.io/ipfs/${headers["x-amz-meta-cid"]}`;
//     });
//     request.send();
//     return responseObj;
//   } catch (error) {
//     throw error;
//   }
// };

export const uploadFileToFileBase = async (file) => {
  if (!file) return;

  if (!file instanceof File) {
    throw new Error("Invalid file type");
  }

  // Create the params for S3 upload
  const params = {
    Bucket: bucketName,
    Key: file.name,
    Body: file,
    ACL: "public-read", // Make it publicly readable
  };

  try {
    const response = await uploadToFileBaseAndReturnUrl(params);
    return response;
  } catch (error) {
    throw error;
  }
};

export const uploadJsonToFileBase = async (jsonData) => {
  if (!jsonData) return;

  if (typeof jsonData !== "object") {
    throw new Error("Invalid JSON data: expected an object");
  }

  // Create the params for S3 upload
  const params = {
    Bucket: bucketName,
    Key: `metadata-${Date.now()}.json`, // Use a unique key for the JSON file
    Body: JSON.stringify(jsonData), // Convert JSON object to string
    ContentType: "application/json", // Set content type to JSON
    ACL: "public-read", // Make it publicly readable
  };

  try {
    const response = await uploadToFileBaseAndReturnUrl(params);
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchNftDetailsByCid = async (cid) => {
  if (!cid) throw new Error("CID is required");

  // Construct the URL to fetch the metadata using the CID
  const url = `https://ipfs.filebase.io/ipfs/${cid}`;

  try {
    const response = await fetch(url);
    const nftDetails = await response.json();
    return nftDetails;
  } catch (error) {
    console.error("Failed to fetch NFT details:", error);
  }
};
