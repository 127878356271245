import "./usernft.scss";
import SellOffersForUser from "../../components/UserNFT/SellOffersForUser/SellOffersForUser";
import LayoutPageNFTMarketplace from "./LayoutPageNFTMarketplace";

const SellOffersForUserPage = () => {
  return (
    <LayoutPageNFTMarketplace>
      <SellOffersForUser />
    </LayoutPageNFTMarketplace>
  );
};

export default SellOffersForUserPage;
