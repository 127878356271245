import { useEffect, useState } from "react";
import NFTService from "../../../services-domain/nft-service";
import { useDispatch } from "react-redux";
import { setShowScreenLoader } from "../../../redux/screenLoader/ScreenLoaderSlice";
import { getCleanedDataForNFT } from "../../../helpers/getCleanedDataForNFT";
import { FaExclamationCircle, FaSearch, FaTimes, FaPlus } from "react-icons/fa";
import "./MarketplaceNFT.scss";
import SellOfferForUser from "../SellOfferForUser/SellOfferForUser";
import { useSearchParams } from "react-router-dom";

function useQueryParams() {
  const [searchParams] = useSearchParams();

  return {
    searchCity: searchParams.get("searchCity") || "",
    checkInDate: searchParams.get("checkInDate") || "",
    checkOutDate: searchParams.get("checkOutDate") || "",
  };
}

const MarketplaceNFT = () => {
  const nftService = new NFTService();
  const dispatch = useDispatch();
  const [allNFTsForUsers, setAllNFTsForUsers] = useState([]);
  const searchParams = useQueryParams();
  const [searchCity, setSearchCity] = useState(searchParams.searchCity || "");
  const [checkInDate, setCheckInDate] = useState(
    searchParams.checkInDate || ""
  );
  const [checkOutDate, setCheckOutDate] = useState(
    searchParams.checkOutDate || ""
  );
  const [facility, setFacility] = useState("");
  const [facilities, setFacilities] = useState([]);

  const removeOffer = (offerId) => {
    setAllNFTsForUsers((prev) =>
      prev.filter((offer) => offer.offerId !== offerId)
    );
  };

  // const filterNFTs = (nfts) => {
  //   // Filter based on City, From Date, To Date, and Facilities
  //   if (
  //     !searchCity &&
  //     !checkInDate &&
  //     !checkOutDate &&
  //     facilities.length === 0
  //   ) {
  //     return nfts;
  //   }
  //   return nfts.filter((nft) => {
  //     const matchesCity = searchCity
  //       ? nft?.hotelDetails?.Location?.City.toLowerCase() ===
  //         searchCity.toLowerCase()
  //       : true;
  //     const matchesCheckInDate = checkInDate
  //       ? nft?.reservationDetails?.FromDate === checkInDate
  //       : true;
  //     const matchesCheckOutDate = checkOutDate
  //       ? nft?.reservationDetails?.ToDate === checkOutDate
  //       : true;

  //     const matchesFacilities =
  //       facilities.length === 0
  //         ? true
  //         : facilities.some((fac) =>
  //             nft?.hotelDetails?.Facilities?.some((hotelFac) =>
  //               hotelFac.toLowerCase().includes(fac.toLowerCase())
  //             )
  //           );

  //     return (
  //       matchesCity &&
  //       matchesCheckInDate &&
  //       matchesCheckOutDate &&
  //       matchesFacilities
  //     );
  //   });
  // };

  const filterNFTs = (nfts) => {
    // Step 1: Filter based on City, From Date, and To Date (if provided)
    let filteredNFTs = nfts.filter((nft) => {
      const matchesCity = searchCity
        ? nft?.hotelDetails?.Location?.City.toLowerCase() ===
          searchCity.toLowerCase()
        : true; // No city filter applied if searchCity is empty

      const matchesCheckInDate = checkInDate
        ? nft?.reservationDetails?.FromDate === checkInDate
        : true; // No check-in date filter applied if checkInDate is empty

      const matchesCheckOutDate = checkOutDate
        ? nft?.reservationDetails?.ToDate === checkOutDate
        : true; // No check-out date filter applied if checkOutDate is empty

      return matchesCity && matchesCheckInDate && matchesCheckOutDate;
    });

    // Step 2: Further filter the results based on Facilities (if provided)
    if (facilities.length > 0) {
      filteredNFTs = filteredNFTs.filter((nft) =>
        facilities.some((fac) =>
          nft?.hotelDetails?.Facilities?.some((hotelFac) =>
            hotelFac?.Name?.toLowerCase().includes(fac.toLowerCase())
          )
        )
      );
    }

    return filteredNFTs;
  };

  const executeSearch = async () => {
    try {
      dispatch(setShowScreenLoader(true));
      const response = await nftService.getSellOffersForAllUsers();
      const preparedResponse = getCleanedDataForNFT(response);
      const filteredNFTs = filterNFTs(preparedResponse);
      setAllNFTsForUsers(filteredNFTs);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setShowScreenLoader(false));
    }
  };

  const handleSearch = async () => {
    if (
      !searchCity &&
      !checkInDate &&
      !checkOutDate &&
      facilities.length === 0
    ) {
      return;
    }
    executeSearch();
  };

  const addFacility = () => {
    if (facility && !facilities.includes(facility)) {
      setFacilities([...facilities, facility]);
      setFacility("");
    }
  };

  const removeFacility = (facilityToRemove) => {
    setFacilities(facilities.filter((fac) => fac !== facilityToRemove));
  };

  useEffect(() => {
    executeSearch(); // Initial load without filters
  }, []);

  return (
    <div className="sell-offer-list-container">
      <div className="search-bar">
        {/* First Row */}
        <div className="first-row">
          <div className="search-field">
            <label htmlFor="city">City</label>
            <input
              type="text"
              id="city"
              placeholder="Enter city"
              value={searchCity}
              onChange={(e) => setSearchCity(e.target.value)}
            />
          </div>
          <div className="search-field">
            <label htmlFor="checkInDate">From Date</label>
            <input
              type="date"
              id="checkInDate"
              value={checkInDate}
              onChange={(e) => setCheckInDate(e.target.value)}
            />
          </div>
          <div className="search-field">
            <label htmlFor="checkOutDate">To Date</label>
            <input
              type="date"
              id="checkOutDate"
              value={checkOutDate}
              onChange={(e) => setCheckOutDate(e.target.value)}
            />
          </div>
        </div>

        {/* Second Row */}
        <div className="second-row">
          <div className="search-field">
            <div className="input-container">
              <input
                type="text"
                id="facilities"
                placeholder="Search hotel by hotel facilities"
                value={facility}
                onChange={(e) => setFacility(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && addFacility()}
              />
            </div>
            <div className="buttons-container">
              <button onClick={addFacility} className="button-marketplace">
                <FaPlus className="button-icon-marketplace" />
                <span className="add-text-marketplace">Add</span>
              </button>
              <button className="button-marketplace" onClick={handleSearch}>
                <FaSearch className="button-icon-marketplace" />
                <span className="button-text-marketplace">Search</span>
              </button>
            </div>
          </div>
          <div className="facilities-list">
            {facilities.map((fac, index) => (
              <div key={index} className="facility-item">
                {fac}
                <FaTimes
                  onClick={() => removeFacility(fac)}
                  className="remove-facility-icon"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {allNFTsForUsers.length > 0 ? (
        <div className="sell-offers-list-marketplace">
          {allNFTsForUsers.map((offer, index) => (
            <SellOfferForUser
              key={index}
              offer={offer}
              accept_user_mode={true}
              removeOffer={removeOffer}
            />
          ))}
        </div>
      ) : (
        <div className="no-offers-container">
          <div className="no-offers-sub-container">
            <FaExclamationCircle size={40} className="no-offers-icon" />
            <h3 className="no-offers-title">
              No sell offers available at the moment
            </h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default MarketplaceNFT;
