import UserDealsNFT from "../../components/UserNFT/UserDeals/UserDealsNFT";
import LayoutPageNFTMarketplace from "./LayoutPageNFTMarketplace";
import "./usernft.scss";

const UserDealsNFTPage = () => {
  return (
    <LayoutPageNFTMarketplace>
      <UserDealsNFT />
    </LayoutPageNFTMarketplace>
  );
};

export default UserDealsNFTPage;
