import NFTTopBar from "../../components/UserNFT/NFTTopBar/NFTTopBar";
import "./usernft.scss";

const LayoutPageNFTMarketplace = ({ children }) => {
  return (
    <div className="main_container_usernft">
      <NFTTopBar />
      <div className="nft-items-container">{children}</div>
    </div>
  );
};

export default LayoutPageNFTMarketplace;
