const makeData = (oneNFTData) => {
  if (
    oneNFTData?.HotelDetails?.ContactDetails &&
    typeof oneNFTData?.HotelDetails?.ContactDetails === "string"
  ) {
    try {
      oneNFTData.HotelDetails.ContactDetails = JSON.parse(
        oneNFTData.HotelDetails.ContactDetails
      );
    } catch (error) {
      console.error(
        "Invalid JSON string in ContactDetails:",
        oneNFTData.HotelDetails.ContactDetails,
        error
      );
    }
  }

  if (
    oneNFTData?.HotelDetails?.Facilities &&
    typeof oneNFTData?.HotelDetails?.Facilities === "string"
  ) {
    try {
      oneNFTData.HotelDetails.Facilities = JSON.parse(
        oneNFTData.HotelDetails.Facilities
      );
    } catch (error) {
      console.error(
        "Invalid JSON string in Facilities:",
        oneNFTData.HotelDetails.Facilities,
        error
      );
    }
  }

  if (
    oneNFTData?.HotelDetails?.Location &&
    typeof oneNFTData?.HotelDetails?.Location === "string"
  ) {
    try {
      oneNFTData.HotelDetails.Location = JSON.parse(
        oneNFTData.HotelDetails.Location
      );
    } catch (error) {
      console.error(
        "Invalid JSON string in Location:",
        oneNFTData.HotelDetails.Location,
        error
      );
    }
  }

  if (oneNFTData?.RoomTypeDetails && oneNFTData?.RoomTypeDetails?.length > 0) {
    for (let roomType of oneNFTData.RoomTypeDetails) {
      if (roomType?.Facilities && typeof roomType?.Facilities === "string") {
        try {
          roomType.Facilities = JSON.parse(roomType.Facilities);
        } catch (error) {
          console.error(
            "Invalid JSON string in Facilities:",
            roomType.Facilities,
            error
          );
        }
      }
    }
  }

  const newNFT = {
    nft: null,
    decodedURI: null,
    offerId: oneNFTData?.OfferId || null,
    offerPrice: oneNFTData?.OfferPrice || null,
    offerDestination: oneNFTData?.OfferDestination || null,
    nfToeknId: oneNFTData?.NFTTokenId || null,
    reservationDetails: oneNFTData?.ReservationDetails,
    hotelDetails: oneNFTData?.HotelDetails,
    roomTypeDetails: oneNFTData?.RoomTypeDetails,
    hotelImages: oneNFTData?.HotelImages?.slice(0, 3)?.map(
      (image) => image.ImageURL
    ),
  };

  return newNFT;
};

export const getCleanedDataForNFT = (nftData) => {
  // Check if it's an array first
  if (Array.isArray(nftData)) {
    let cleanedData = [];
    for (let oneNFTData of nftData) {
      const newNFTMake = makeData(oneNFTData);
      const newNFT = { ...newNFTMake, nft: oneNFTData.nft };
      cleanedData.push(newNFT);
    }
    return cleanedData;
  }
  // If it's not an array but an object
  else if (typeof nftData === "object") {
    return makeData(nftData);
  }
};
