import "./DealCreatedUser.scss";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { setShowScreenLoader } from "../../../redux/screenLoader/ScreenLoaderSlice";
import { LocalStorageKeys, LoginMethods } from "../../../constants/constants";
import NFTItem from "../NFTItem/NFTItem";
import { cancelOfferXRPL } from "../../../services-common/xumm-api-service";
import { cancelOfferSOCIAL } from "../../../services-common/web3auth-service";

const DealCreatedUser = ({ offer, removeOffer }) => {
  const isSocialLogin =
    localStorage.getItem(LocalStorageKeys.LoginMethod) === LoginMethods.SOCIAL;
  const dispatch = useDispatch();

  const handleClearence = async (offerId, nfTokenId) => {
    if (!offerId || !nfTokenId) {
      toast.error("Offer not found");
      return;
    }

    try {
      let response;
      dispatch(setShowScreenLoader(true));
      if (isSocialLogin) {
        response = await cancelOfferSOCIAL(offerId, nfTokenId);
      } else {
        response = await cancelOfferXRPL(offerId, nfTokenId);
      }
      toast.success("Offer cancelled successfully");
      removeOffer(offerId);
      console.log("token remove response: ==> ", response);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setShowScreenLoader(false));
    }
  };

  return (
    <>
      <NFTItem nft={offer} handleClearence={handleClearence} />
    </>
  );
};

export default DealCreatedUser;
