import { Xumm } from "xumm";
import {
  LocalStorageKeys,
  LoginMethods,
  PaymentResults,
  TransactionTypes,
} from "../constants/constants";
import {
  loginSuccessfully,
  logoutSuccessfully,
} from "../redux/LoginState/LoginStateSlice";
import { store } from "../redux/store";
import { setShowScreenLoader } from "../redux/screenLoader/ScreenLoaderSlice";
import toast from "react-hot-toast";
import {
  escrowCreateSocial,
  escrowFinishSocial,
  makeAPaymentSocial,
  nftAcceptOfferSOCIAL,
} from "./web3auth-service";
import UserService from "../services-domain/user-service-copy";
import {
  fetchNftDetailsByCid,
  uploadJsonToFileBase,
} from "../helpers/uploadToFileBase";
import NFTService from "../services-domain/nft-service";
import {
  xrpToDrops,
  isoTimeToRippleTime,
  convertStringToHex,
  convertHexToString,
  Wallet,
} from "xrpl";
import { getCleanedDataForNFT } from "../helpers/getCleanedDataForNFT";

const xrpl = require("xrpl");

let xumm = null;
const client = new xrpl.Client(process.env.REACT_APP_XRPL_WS_URL);
const NFT_ISSUER_ADDRESS = process.env.REACT_APP_TPQ_ISSUER_ADDRESS;
const NFT_ISSUER_SECRET = process.env.REACT_APP_TPQ_ISSUER_SECRET;

// Client connection
const connectClient = async () => {
  try {
    await client.connect();
    console.log("Connected to XRPL WebSocket client");
  } catch (error) {
    console.error("Error connecting to XRPL WebSocket client:", error);
    toast.error("Failed to connect to XRPL WebSocket client");
  }
};
connectClient();

const proceedWithXumm = async (incomingTx) => {
  if (!xumm) {
    await xummAuthorize();
  }

  if (!client.isConnected()) {
    await connectClient();
  }

  // Create the payload for XUMM signing
  let createdPayload;
  try {
    createdPayload = await xumm.payload?.create(incomingTx);
    console.log("XUMM Payload Created:", createdPayload);
  } catch (error) {
    console.error("Error creating XUMM payload:", error);
    throw new Error("Failed to create XUMM payload");
  }

  // Open the payload in the XUMM app (QR code can be scanned)
  const payloadWindow = window.open(
    createdPayload.next.no_push_msg_received,
    "Xaman Pay",
    "height=750,width=600, right=300, resizable=no, left=300,top=100"
  );

  // Poll the XUMM API for the payload status
  let signed = false;
  let payload = null;
  try {
    while (!signed) {
      const result = await xumm.payload.get(createdPayload.uuid);
      if (result.meta.signed === true) {
        signed = true;
        payload = result;

        // Close the opened window
        if (payloadWindow) {
          payloadWindow.close();
        }

        console.log("Transaction signed via XUMM:", payload);
      } else if (
        result.meta.signed === false &&
        result.meta.cancelled === true
      ) {
        console.log("Transaction was not signed or was cancelled");
        if (payloadWindow) {
          payloadWindow.close();
        }
        throw new Error("Transaction was not signed or was cancelled");
      }
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Poll every 2 seconds
    }
  } catch (error) {
    console.error("Error during XUMM payload polling:", error);
    throw new Error("Failed to sign the transaction with XUMM");
  }

  // Submit the signed transaction to XRPL
  if (payload && payload.response.hex) {
    try {
      const result = await client.submitAndWait(payload.response.hex);
      if (result.result.meta.TransactionResult === PaymentResults.tesSUCCESS) {
        result.issued_user_token = payload.application.issued_user_token;
        return result;
      } else {
        throw new Error(
          "Transaction failed with result: " +
            result.result.meta.TransactionResult
        );
      }
    } catch (error) {
      console.error("Error submitting transaction to XRPL:", error);
      throw new Error("Failed to submit the transaction to XRPL");
    }
  } else {
    throw new Error("Invalid payload or missing hex data in signed response");
  }
};

export async function xummAuthorize() {
  console.log("inside xummAuthorize");
  xumm = new Xumm(process.env.REACT_APP_XUMM_APIKEY);
  const userService = new UserService();
  const loginState = localStorage.getItem("loginState");
  const transactionInProgress =
    localStorage.getItem(LocalStorageKeys.TransactionInProgress) === "true";
  const isEscrowCreateInProgress =
    localStorage.getItem(LocalStorageKeys.EscrowCreateInProgress) === "true";
  if (loginState) {
    const parsedLoginState = JSON.parse(loginState);

    // Check the isLoggedIn property on the parsed object
    if (parsedLoginState.isLoggedIn === true && !transactionInProgress) return;
  }
  try {
    const res = await xumm.authorize();
    if (res && res.me) {
      const existingAddess = localStorage.getItem(
        LocalStorageKeys.AccountAddress
      );
      localStorage.removeItem(LocalStorageKeys.EscrowCreateInProgress);
      if (isEscrowCreateInProgress) return res.me.account;
      if (existingAddess && existingAddess !== res.me.account) return;
      store.dispatch(setShowScreenLoader(true));
      const responseGetUser = await userService.getUserByWalletAddress(
        res.me.account
      );
      if (!responseGetUser) {
        // initiate trust line
        try {
          const isTrustLineInitiated = await createTrustLineXRPL(
            res.me.account
          );

          if (isTrustLineInitiated) {
            // Register the user if not already registered
            const respUser = await userService.registerUser({
              walletAddress: res.me.account,
              loginMethod: LoginMethods.XRPL,
            });
            console.log("User registered successfully: ", respUser);
          }
        } catch (e) {
          console.log("error", e);
          throw e;
        }
      }
      localStorage.setItem(LocalStorageKeys.LoginMethod, LoginMethods.XRPL);
      localStorage.setItem(LocalStorageKeys.AccountAddress, res.me.account);
      store.dispatch(loginSuccessfully(res.me.account));
      store.dispatch(setShowScreenLoader(false));
      return true;
    } else {
      throw new Error("Failed to authorize");
    }
  } catch (e) {
    throw e;
  }
}

export async function xummLogout() {
  localStorage.clear();
  store.dispatch(logoutSuccessfully());
  try {
    if (xumm) {
      await xumm.logout();
      xumm = null;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function createPayloadAndSubmit(
  sourceAccount,
  destinationAccount,
  callback,
  amount,
  currency = null,
  issuer = null
) {
  let amountField = String(amount * 1000000);
  if (currency && issuer) {
    amountField = {
      value: String(amount),
      currency: currency,
      issuer: issuer,
    };
  }

  const payload = await xumm.payload?.createAndSubscribe(
    {
      TransactionType: "Payment",
      Destination: destinationAccount,
      Account: sourceAccount,
      Amount: amountField,
    },
    callback
  );

  return payload;
}

export async function subscribe(createdPayload) {
  return await xumm.payload?.subscribe(createdPayload);
}

/**
 *  Use this method for Payments
 * @param sourceAddress
 * @param destinationAddress
 * @param amount
 * @param finishTime
 * @param destinationTag Number | greater than 1000 to represent the reason
 * @param currency
 * @param issuer
 * @returns {Promise<constants.PaymentResults>}
 */
export async function showPayQRWindow(
  sourceAddress,
  destinationAddress,
  amount,
  finishAfterTime,
  cancelAfterTime,
  destinationTag,
  currency,
  issuer,
  transactionType,
  escrowSequence = 0
) {
  let payload = null;
  localStorage.setItem(LocalStorageKeys.TransactionInProgress, "true");
  const isSocialLogin =
    localStorage.getItem(LocalStorageKeys.LoginMethod) === LoginMethods.SOCIAL;

  // Create payload for the transaction
  if (transactionType === TransactionTypes.PAYMENT) {
    let response;
    try {
      if (isSocialLogin) {
        response = await makeAPaymentSocial(
          sourceAddress,
          destinationAddress,
          amount
        );
      } else {
        response = await makePaymentXRPL(
          sourceAddress,
          destinationAddress,
          amount
        );
      }
      return response;
    } catch (err) {
      console.log("Error making payment: ", err);
      throw err;
    }
  } else if (transactionType === TransactionTypes.ESCROW_CREATE) {
    const finishAfterRipple = isoTimeToRippleTime(finishAfterTime);
    const cancelAfterRipple = isoTimeToRippleTime(cancelAfterTime);
    const lastLedgerSequence = await getLastLedgerSequence();
    const isInternalWalletTransaction =
      localStorage.getItem(LocalStorageKeys.PayWithInternalWallet) === "true";
    localStorage.setItem(LocalStorageKeys.EscrowCreateInProgress, "true");

    const tx = {
      TransactionType: TransactionTypes.ESCROW_CREATE,
      Account: null,
      Destination: destinationAddress,
      Amount: xrpToDrops(amount),
      FinishAfter: finishAfterRipple,
      CancelAfter: cancelAfterRipple,
      Sequence: null,
      LastLedgerSequence: lastLedgerSequence,
    };

    let response;

    if (isInternalWalletTransaction) {
      const accSeq = await getAccountSequence(sourceAddress);
      const newTx = { ...tx, Sequence: accSeq, Account: sourceAddress };
      response = await escrowCreateSocial(newTx);
    } else {
      if (xumm) {
        await xumm.logout();
      }
      const senderAddress = await xummAuthorize();
      const accSeq = await getAccountSequence(senderAddress);
      const newTx = {
        ...tx,
        Account: senderAddress,
        Sequence: accSeq,
      };
      const answer = await proceedWithXumm(newTx);
      response = {
        transactionResult: PaymentResults.tesSUCCESS,
        sequence: answer.result.Sequence,
        issuedUserToken: answer.issued_user_token,
        finishAfterTime: answer.result.FinishAfter,
        cancelAfterTime: answer.result.CancelAfter,
      };
    }
    return response;
  } else if (transactionType === TransactionTypes.ESCROW_CANCEL) {
    const response = await cancelEscrowXRPL(escrowSequence, sourceAddress);
    return response;
  } else if (transactionType === TransactionTypes.ESCROW_FINISH) {
    const lastLedgerSequence = await getLastLedgerSequence();
    const accountSequence = await getAccountSequence(destinationAddress);
    localStorage.setItem(LocalStorageKeys.EscrowCreateInProgress, "true");

    let response;

    const tx = {
      TransactionType: TransactionTypes.ESCROW_FINISH,
      Account: destinationAddress,
      Owner: sourceAddress,
      OfferSequence: escrowSequence,
      Sequence: accountSequence,
      LastLedgerSequence: lastLedgerSequence,
    };

    if (isSocialLogin) {
      response = await escrowFinishSocial(tx);
    } else {
      if (!xumm) {
        await xummAuthorize();
      }
      response = await proceedWithXumm(tx);
      if (
        response?.result?.meta?.TransactionResult === PaymentResults.tesSUCCESS
      ) {
        response = {
          ...response,
          transactionResult: PaymentResults.tesSUCCESS,
        };
      }
    }
    localStorage.removeItem(LocalStorageKeys.EscrowCreateInProgress);
    return response;
  } else {
    payload = await createTransactionPayload(
      sourceAddress,
      destinationAddress,
      amount,
      destinationTag,
      currency,
      issuer
    );
  }

  if (!payload) {
    toast.error("Incorrect transaction type");
    return;
  }
  const subscription = await subscribe(payload);

  const newWindow = window.open(
    payload.next.no_push_msg_received,
    "Xaman Pay",
    "height=750,width=600, right=300, resizable=no, left=300,top=100"
  );

  store.dispatch(setShowScreenLoader(false));
  const tmout = setInterval(() => {
    if (newWindow.closed) {
      subscription.resolve(PaymentResults.ABORTED);
    }
  }, 1000);

  subscription.websocket.onmessage = async (message) => {
    const data = JSON.parse(message.data.toString());

    if (Object.keys(data).indexOf("signed") > -1 && data.signed) {
      let escrowSequence = 0;
      let issuedUserToken = "";
      let result = "";

      const transactionResult = await xumm.payload.get(payload.uuid);
      issuedUserToken = transactionResult.application.issued_user_token;

      if (transactionResult.response.txid) {
        const txDetails = await client.request({
          command: "tx",
          transaction: transactionResult.response.txid,
        });

        if (txDetails && txDetails.result && txDetails.result.Sequence) {
          escrowSequence = txDetails.result.Sequence;
          result = txDetails.result.meta.TransactionResult;
        }
      }
      const response = {
        sequence: escrowSequence,
        issuedUserToken: issuedUserToken,
        finishAfterTime: finishAfterTime,
        cancelAfterTime: cancelAfterTime,
        transactionResult: result,
      };
      subscription.resolve(response);

      return data;
    } else if (Object.keys(data).indexOf("signed") > -1 && !data.signed) {
      subscription.resolve(PaymentResults.REJECTED);
      return data;
    }
  };

  const paymentResult = await subscription.resolved;
  clearInterval(tmout);
  newWindow.close();
  console.log(paymentResult);
  return paymentResult;
}

async function getAccountSequence(account) {
  if (!client.isConnected()) {
    await connectClient();
  }
  const accountInfo = await client.request({
    command: "account_info",
    account: account,
  });
  return accountInfo.result.account_data.Sequence;
}

// Function to get a sufficiently high LastLedgerSequence
async function getLastLedgerSequence() {
  if (!client.isConnected()) {
    await connectClient();
  }
  const ledger = await client.request({
    command: "ledger",
    ledger_index: "validated",
  });
  return ledger.result.ledger_index + 50; // Add a reasonable buffer to the current ledger index
}

// Function to submit a transaction with retry logic
async function submitTransactionWithRetry(signedTx, retryCount = 5) {
  let lastError;
  for (let i = 0; i < retryCount; i++) {
    try {
      if (!client.isConnected()) {
        await connectClient();
      }
      const result = await client.submitAndWait(signedTx.tx_blob);
      return result;
    } catch (error) {
      lastError = error;
      if (error.data && error.data.error === "temBAD_EXPIRATION") {
        console.log("Retrying transaction due to temBAD_EXPIRATION...");
        continue;
      } else {
        throw error;
      }
    }
  }
  throw lastError;
}

// Create transaction payload
async function createTransactionPayload(
  sourceAddress,
  destinationAddress,
  amount,
  destinationTag = 1000,
  currency = null,
  issuer = null,
  newXumm
) {
  if (!client.isConnected()) {
    await connectClient();
  }
  let amountField = String(amount * 1000000);
  if (currency && issuer) {
    amountField = {
      value: String(amount),
      currency: currency,
      issuer: issuer,
    };
  }
  const lastLedgerSequence = await getLastLedgerSequence();
  const accountSequence = await getAccountSequence(sourceAddress);
  const tx = {
    TransactionType: "Payment",
    Destination: destinationAddress,
    Account: sourceAddress,
    Amount: amountField,
    DestinationTag: destinationTag,
    Sequence: accountSequence,
    LastLedgerSequence: lastLedgerSequence,
  };
  return await newXumm.payload?.create(tx);
}

// Create Escrow
async function createEscrow(
  sourceAddress,
  destinationAddress,
  amount,
  finishAfterRipple,
  cancelAfterRipple,
  currency = null,
  issuer = null
) {
  try {
    let amountField = String(amount * 1000000);
    if (currency && issuer) {
      amountField = {
        value: String(amount),
        currency: currency,
        issuer: issuer,
      };
    }
    const lastLedgerSequence = await getLastLedgerSequence();
    const accountSequence = await getAccountSequence(sourceAddress);

    const tx = {
      TransactionType: "EscrowCreate",
      Account: sourceAddress,
      Destination: destinationAddress,
      Amount: xrpToDrops(amount),
      FinishAfter: finishAfterRipple,
      CancelAfter: cancelAfterRipple,
      Sequence: accountSequence,
      LastLedgerSequence: lastLedgerSequence,
    };

    return await xumm.payload?.create(tx);
  } catch (err) {
    throw err;
  }
}

// Finish Escrow
async function finishEscrow(escrowSequence, destinationAddress, sourceAddress) {
  const lastLedgerSequence = await getLastLedgerSequence();
  const accountSequence = await getAccountSequence(destinationAddress);

  const tx = {
    TransactionType: "EscrowFinish",
    Account: destinationAddress,
    Owner: sourceAddress,
    OfferSequence: escrowSequence,
    Sequence: accountSequence,
    LastLedgerSequence: lastLedgerSequence,
  };

  return await xumm.payload?.create(tx);
}

// Cancel Escrow
async function cancelEscrowXRPL(escrowSequence, sourceAddress) {
  try {
    const lastLedgerSequence = await getLastLedgerSequence();
    const accountSequence = await getAccountSequence(sourceAddress);

    const tx = {
      TransactionType: TransactionTypes.ESCROW_CANCEL,
      Account: sourceAddress,
      Owner: sourceAddress,
      OfferSequence: escrowSequence,
      Sequence: accountSequence,
      LastLedgerSequence: lastLedgerSequence,
    };

    const answer = await proceedWithXumm(tx);
    return answer;
  } catch (err) {
    throw err;
  }
}

export const getAllXRPTransactions = async (account) => {
  try {
    if (!client.isConnected()) {
      await connectClient();
    }
    const transactions = await client.request({
      command: "account_tx",
      account: account,
    });
    const responseArray = transactions.result.transactions.map((tx) => tx.tx);
    let returnXRPArray = [];
    let returnTPQArray = [];
    responseArray.forEach((tx) => {
      if (tx.Amount instanceof Object) {
        tx.Amount = tx.Amount.value;
        returnTPQArray.push(tx);
      } else {
        returnXRPArray.push(tx);
      }
    });
    returnXRPArray = returnXRPArray.filter(
      (tx) => tx.TransactionType !== TransactionTypes.TRUST_SET
    );

    const returnObject = {
      xrp: returnXRPArray,
      tpq: returnTPQArray,
    };
    return returnObject;
  } catch (err) {
    throw err;
  }
};

export const getAccountNFTsFromLedger = async (account) => {
  try {
    if (!client.isConnected()) {
      await connectClient();
    }
    const nftDetails = await client.request({
      command: "account_nfts",
      account: account,
    });
    const accNFTs = nftDetails?.result?.account_nfts || [];
    return accNFTs;
  } catch (err) {
    throw err;
  }
};

export const getAllAccountNFTs = async (account) => {
  const nftService = new NFTService();
  try {
    if (!client.isConnected()) {
      await connectClient();
    }
    const nftDetails = await client.request({
      command: "account_nfts",
      account: account,
    });
    const accNFTs = nftDetails?.result?.account_nfts || [];
    const returnNFTs = [];

    // Use for...of to handle asynchronous operations correctly
    for (const nft of accNFTs) {
      const URI = nft?.URI || "";
      const decodedURI = convertHexToString(URI);
      const cid = decodedURI.split("/ipfs/")[1];
      if (cid) {
        const details = await fetchNftDetailsByCid(cid);
        if (details?.reservationCode) {
          const reservationDetails =
            await nftService.getReservationDetailsForNFT({
              reservationCode: details.reservationCode,
            });
          if (
            reservationDetails &&
            Object.keys(reservationDetails).length > 0
          ) {
            const cleanedData = getCleanedDataForNFT(reservationDetails);
            const newNFT = {
              ...cleanedData,
              nft: nft,
              decodedURI: decodedURI,
            };
            returnNFTs.push(newNFT);
          }
        }
      }
    }
    return returnNFTs;
  } catch (err) {
    throw err;
  }
};

export const getAccountBalance = async (account) => {
  const TPQ_HEX = process.env.REACT_APP_TPQ_CURRENCY_HEX;
  try {
    // Ensure the client is connected
    if (!client.isConnected()) {
      await connectClient();
    }

    // Fetch account info to get the XRP balance
    const accountInfo = await client.request({
      command: "account_info",
      account: account,
      ledger_index: "validated",
    });

    const accountLines = await client.request({
      command: "account_lines",
      account: account,
      ledger_index: "validated",
    });

    const lines = accountLines?.result?.lines;
    let tpqBalance = 0;

    if (lines && lines.length > 0) {
      const tpqLine = lines.find((line) => line.currency === TPQ_HEX);
      if (tpqLine) {
        tpqBalance = tpqLine.balance;
      }
    }

    const balanceInDrops = accountInfo.result.account_data.Balance;
    const balanceInXRP = parseFloat(balanceInDrops / 1000000).toFixed(6);

    // Fetch the current XRP to USD exchange rate
    const response = await fetch(
      "https://api.coingecko.com/api/v3/simple/price?ids=ripple&vs_currencies=usd"
    );
    const data = await response.json();
    const xrpToUsdRate = data.ripple.usd;

    // Convert balance to USD
    const balanceInUSD = balanceInXRP * xrpToUsdRate;

    // Format balance to 2 decimal places
    const formattedBalanceInUSD = balanceInUSD.toFixed(2);

    return {
      xrp: balanceInXRP,
      usd: formattedBalanceInUSD,
      tpq: tpqBalance,
    };
  } catch (err) {
    throw err;
  }
};

export function xummOff(event) {
  xumm.off(event);
}

export async function makePaymentXRPL(
  sourceAddress,
  destinationAddress,
  amount
) {
  try {
    if (xumm) {
      await xumm.logout();
    }
    await xummAuthorize();
    const lastLedgerSequence = await getLastLedgerSequence();
    const paymentTx = {
      TransactionType: TransactionTypes.PAYMENT,
      Account: sourceAddress,
      Amount: xrpToDrops(amount),
      Destination: destinationAddress,
      LastLedgerSequence: lastLedgerSequence,
    };
    const result = await proceedWithXumm(paymentTx);
    return result;
  } catch (err) {
    throw err;
  }
}

async function createTrustLineXRPL(userAccountAddress) {
  const currency = process.env.REACT_APP_TPQ_CURRENCY_HEX;
  const issuerAddress = process.env.REACT_APP_TPQ_ISSUER_ADDRESS;
  const trustLineLimit = process.env.REACT_APP_TPQ_TRUSTLINE_LIMIT;

  if (!client.isConnected()) {
    await connectClient();
  }

  const transaction = {
    TransactionType: TransactionTypes.TRUST_SET,
    Account: userAccountAddress,
    LimitAmount: {
      currency: currency,
      issuer: issuerAddress,
      value: trustLineLimit,
    },
  };

  try {
    const response = await proceedWithXumm(transaction);
    console.log("Trust Line initiated successfully.");
    return true;
  } catch (err) {
    console.log("error", err);
    throw err;
  }
}

export const reduceBalanceFromXRPL = async () => {
  const TPQ_REDUCE_AMOUNT = 10;
  const destinationAddress = process.env.REACT_APP_TPQ_ISSUER_ADDRESS;
  const sourceAddress = localStorage.getItem(LocalStorageKeys.AccountAddress);
  const lastLedgerSequence = await getLastLedgerSequence();
  const currency = process.env.REACT_APP_TPQ_CURRENCY_HEX;
  const issuerAddress = process.env.REACT_APP_TPQ_ISSUER_ADDRESS;

  const tx = {
    TransactionType: TransactionTypes.PAYMENT,
    Amount: {
      currency: currency,
      issuer: issuerAddress,
      value: TPQ_REDUCE_AMOUNT,
    },
    Destination: destinationAddress,
    LastLedgerSequence: lastLedgerSequence,
  };

  console.log("TPQ reduction transaction: ", tx);

  try {
    const response = await proceedWithXumm(tx);
    console.log("TPQ reduction result: ", response);
    return response;
  } catch (e) {
    throw e;
  }
};

// Mint transfarable NFT (Non Fungible Token) to the user
export const mintNFT_XRPL = async (nftData) => {
  const walletCreator = localStorage.getItem(LocalStorageKeys.AccountAddress);
  try {
    console.log("NFTokenMint data:", nftData);
    const responseObj = await uploadJsonToFileBase(nftData);

    const tx = {
      TransactionType: TransactionTypes.NFTokenMint,
      Account: walletCreator,
      NFTokenTaxon: 0,
      Flags: 9, // 9 - tfBurnable + tfTransferable
      URI: convertStringToHex(responseObj.url),
    };

    const answer = await proceedWithXumm(tx);
    console.log("NFT mint result XRPL ===> : ", answer);

    const returnResponse = {
      reservationCode: nftData.reservationCode,
      uri: responseObj.url,
      nftId: answer.result.meta.nftoken_id,
    };

    console.log("NFT mint returnResponse: ", returnResponse);

    return returnResponse;
  } catch (e) {
    throw e;
  }
};

export const burnNFT_XRPL = async (nfTokenId) => {
  const burnerWalletAddress = localStorage.getItem(
    LocalStorageKeys.AccountAddress
  );

  try {
    const nftBurnTx = {
      TransactionType: TransactionTypes.NFTokenBurn,
      Account: burnerWalletAddress,
      NFTokenID: nfTokenId,
    };
    const answer = await proceedWithXumm(nftBurnTx);
    console.log("NFT burn result: ", answer);
    return answer;
  } catch (e) {
    throw e;
  }
};

export const transferNFTOwnershipXRPL = async (nftData, newOwner) => {
  const nftService = new NFTService();

  const accountAddress = localStorage.getItem(LocalStorageKeys.AccountAddress);
  let transferTx = {
    TransactionType: TransactionTypes.NFTokenCreateOffer,
    Account: accountAddress,
    Destination: newOwner,
    NFTokenID: nftData.NFTTokenId,
    Amount: xrpToDrops(0),
    Flags: 1, // 1 - tfSell - for transfer
  };

  console.log("Transfer NFT ownership transaction: ", transferTx);

  try {
    // check already transfer offer created
    const transferAlreadyCreated =
      await nftService.checkAlreadyOwnershipTransferCreated({
        nftId: nftData.NFTTokenId,
        destination: newOwner,
      });

    console.log("Transfer already created: ", transferAlreadyCreated);

    if (transferAlreadyCreated) {
      return { message: 409 };
    }

    const answer = await proceedWithXumm(transferTx);
    console.log("NFT transfer result: ", answer);

    const obj = {
      nftId: answer.result.NFTokenID,
      OfferPrice: 0,
      offerDestination: newOwner,
      offerId: answer.result.meta.offer_id,
      createdBy: accountAddress,
    };

    const result = await nftService.addSellOfferDataWithDestination(obj);
    console.log("Ownership transfer result add DB: ", result);
    return result;
  } catch (err) {
    console.log("Error transferring NFT ownership: ", err);
    throw err;
  }
};

export const createNFTSellOfferXRPL = async (nftData, value) => {
  const nftService = new NFTService();
  const accountAddress = localStorage.getItem(LocalStorageKeys.AccountAddress);

  if (!value || value <= 0 || isNaN(value) || value === "") {
    throw new Error("Invalid offer price");
  }
  if (!nftData) {
    throw new Error("Invalid NFT data");
  }
  if (!accountAddress) {
    throw new Error("Account address not found");
  }

  let sellOfferTx = {
    TransactionType: TransactionTypes.NFTokenCreateOffer,
    Account: accountAddress,
    NFTokenID: nftData.NFTTokenId,
    Amount: xrpToDrops(value),
    Flags: 1, // 1 - tfSell - for sell offer
  };

  try {
    const answer = await proceedWithXumm(sellOfferTx);
    console.log("NFT sell offer result: ", answer);

    const obj = {
      nftId: answer.result.NFTokenID,
      offerPrice: value,
      offerId: answer.result.meta.offer_id,
      createdBy: accountAddress,
    };

    console.log("===>going to add data to the DB", obj);

    const result = await nftService.addSellOfferDataWithoutDestination(obj);
    console.log("Sell offer result add DB: ", result);
    return result;
  } catch (err) {
    throw err;
  }
};

export const nftAcceptOfferXRPL = async (
  offerId,
  nftId,
  user_mode_common = false
) => {
  const nftService = new NFTService();
  const userAccountAddress = localStorage.getItem(
    LocalStorageKeys.AccountAddress
  );

  if (!userAccountAddress) {
    throw new Error("Account address not found");
  }

  const transferTx = {
    TransactionType: TransactionTypes.NFTokenAcceptOffer,
    Account: userAccountAddress,
    NFTokenSellOffer: offerId,
  };

  console.log("Accept NFT offer transaction: ", transferTx);

  try {
    const result = await proceedWithXumm(transferTx);
    console.log("=====================================>");
    console.log("NFT offer accepted successfully:", result);

    if (user_mode_common) {
      const acceptOfferResponse = await nftService.acceptSellOfferForCommonUser(
        {
          offerId: offerId,
          nftId: nftId,
          destination: userAccountAddress,
        }
      );
      console.log("=====================================>");
      console.log("NFT offer common user DB response:", acceptOfferResponse);
      return acceptOfferResponse;
    } else {
      const acceptOfferResponse =
        await nftService.acceptSellOfferForSpecificUser({
          offerId: offerId,
          nftId: nftId,
          destination: userAccountAddress,
        });
      console.log("=====================================>");
      console.log("NFT offer specific user DB response:", acceptOfferResponse);
      return acceptOfferResponse;
    }
  } catch (err) {
    console.log("Error transferring NFT ownership: ", err);
    throw err;
  }
};

export const getSellOffersForNFT_XRPL = async (nftId) => {
  try {
    if (!client.isConnected()) {
      await connectClient();
    }

    const offers_exist_earlier_response = await client.request({
      command: "nft_sell_offers",
      nft_id: nftId,
    });

    // Return offers if they exist, otherwise return an empty array
    const result_res = offers_exist_earlier_response?.result?.offers || [];
    return result_res;
  } catch (err) {
    // Check if the error is the specific RippledError you're expecting
    if (err?.message.includes("The requested object was not found")) {
      console.log("No sell offers found for this NFT.");
      return []; // Return an empty array if no offers exist
    }

    // For any other error, rethrow it
    console.log("Error getting sell offers for NFT: ", err);
    throw err;
  }
};

export const cancelOfferXRPL = async (offerId, nfTokenId) => {
  const nftService = new NFTService();
  const accountAddress = localStorage.getItem(LocalStorageKeys.AccountAddress);
  if (!offerId) {
    throw new Error("Invalid offer ID");
  }

  if (!accountAddress) {
    throw new Error("Account address not found");
  }

  const cancelOfferTx = {
    TransactionType: TransactionTypes.NFTokenCancelOffer,
    Account: accountAddress,
    NFTokenOffers: [offerId], // The list of offer IDs to cancel
  };

  try {
    const result = await proceedWithXumm(cancelOfferTx);
    console.log("=====================================>");
    console.log("NFT offer canceled successfully:", result);
    const obj = {
      offerId: offerId,
      nftId: nfTokenId,
    };
    const response = await nftService.removeSellOffer(obj);
    console.log("====> Cancel offer response DB: ", response);
  } catch (err) {
    console.log("Error transferring NFT ownership: ", err);
    throw err;
  }
};

export const mintNftAndTransferOwnershipUsingCompanyAccount = async (
  nftData,
  newOwner
) => {
  const nftService = new NFTService();
  const isSocialLigin =
    localStorage.getItem(LocalStorageKeys.LoginMethod) === LoginMethods.SOCIAL;

  if (!nftData) {
    throw new Error("Invalid NFT data");
  }
  if (!newOwner) {
    throw new Error("New owner's address not found");
  }

  // Connect to the XRPL client
  if (!client.isConnected()) {
    await connectClient();
  }

  try {
    const responseObj = await uploadJsonToFileBase(nftData);

    const mintNftTx = {
      TransactionType: TransactionTypes.NFTokenMint,
      Account: NFT_ISSUER_ADDRESS,
      NFTokenTaxon: 0,
      Flags: 9, // 9 - tfBurnable + tfTransferable
      URI: convertStringToHex(responseObj.url),
    };

    // Create a wallet from the seed
    const wallet = Wallet.fromSecret(NFT_ISSUER_SECRET);

    // Sign the mint transaction
    const preparedTx = await client.autofill(mintNftTx);
    const signedMintTx = wallet.sign(preparedTx);

    // Submit the mint transaction
    const mintedNftResponse = await client.submitAndWait(signedMintTx.tx_blob);

    const mintedNftId = mintedNftResponse.result.meta.nftoken_id; // Get the minted NFT ID

    const nftEntityData = {
      owner: newOwner,
      reservationCode: nftData.reservationCode,
      uri: responseObj.url,
      nftId: mintedNftId,
    };

    const responseTableEntryAdd = await nftService.addNftData(nftEntityData);

    // Prepare transfer transaction using NFTokenCreateOffer
    const transferTx = {
      TransactionType: TransactionTypes.NFTokenCreateOffer,
      Account: NFT_ISSUER_ADDRESS,
      Destination: newOwner,
      NFTokenID: mintedNftId,
      Amount: xrpToDrops(0), // Use 0 if just transferring ownership
      Flags: 1, // 1 - tfSell - for transfer
    };

    // Sign the transfer transaction
    const preparedTxTransfer = await client.autofill(transferTx);
    const signedTransferTx = wallet.sign(preparedTxTransfer); // Only sign the transfer transaction once

    // Submit the transfer transaction
    const transferResponse = await client.submitAndWait(
      signedTransferTx.tx_blob
    );

    // add transfer result to DB
    const obj_addTransferToDB = {
      nftId: mintedNftId,
      OfferPrice: 0,
      offerDestination: newOwner,
      offerId: transferResponse.result.meta.offer_id,
      createdBy: newOwner,
    };

    const resultAddTransferToDB =
      await nftService.addSellOfferDataWithDestination(obj_addTransferToDB);

    if (isSocialLigin) {
      const nftOfferAcceptResponse = await nftAcceptOfferSOCIAL(
        transferResponse.result.meta.offer_id,
        transferResponse.result.NFTokenID,
        false
      );
    } else {
      const nftOfferAcceptResponse = await nftAcceptOfferXRPL(
        transferResponse.result.meta.offer_id,
        transferResponse.result.NFTokenID,
        false
      );
    }

    return true;
  } catch (err) {
    console.error("Error during NFT minting or transfer:", err);
    throw err;
  }
};
