import MarketplaceNFT from "../../components/UserNFT/MarketplaceNFT/MarketplaceNFT";
import LayoutPageNFTMarketplace from "./LayoutPageNFTMarketplace";
import "./usernft.scss";

const MarketplaceNFTPage = () => {
  return (
    <LayoutPageNFTMarketplace>
      <MarketplaceNFT />
    </LayoutPageNFTMarketplace>
  );
};

export default MarketplaceNFTPage;
