import "./NFTTopBar.scss";
import { links_usernft } from "./links";
import { Link, useLocation } from "react-router-dom";

const NFTTopBar = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="nft-sidebar-container">
      {links_usernft.map((link) => {
        const Icon = link.icon;
        return (
          <Link
            key={link.id}
            className={`nft-sidebar-icon ${
              currentPath.startsWith(link.path) ? "active" : ""
            }`}
            to={link.path}
            style={{ textDecoration: "none" }}
          >
            <div className="nft-sidebar-single-icon-container">
              <Icon size={24} className="nft-sidebar-single-icon" />
            </div>
            <span>{link.title}</span>
          </Link>
        );
      })}
    </div>
  );
};

export default NFTTopBar;
