import ContractService from "../services-common/contract-service";
const constants = require("./../constants");

export default class NFTService {
  static instance = NFTService.instance || new NFTService();
  contractService = ContractService.instance;

  async addNftData(data) {
    const submitObj = {
      type: constants.RequestTypes.NFT,
      subType: constants.RequestSubTypes.AddNFTData,
      data: data,
    };

    // check whether all three fields on submitObj are present
    if (!submitObj.type || !submitObj.subType || !submitObj.data) {
      throw new Error("Invalid data. type, subType and data are required.");
    }

    try {
      const result = await this.contractService.submitInputToContract(
        submitObj
      );
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async addSellOfferDataWithDestination(data) {
    const submitObj = {
      type: constants.RequestTypes.NFT,
      subType: constants.RequestSubTypes.AddSellOfferDataWithDestination,
      data: data,
    };

    // check whether all three fields on submitObj are present
    if (!submitObj.type || !submitObj.subType || !submitObj.data) {
      throw new Error("Invalid data. type, subType and data are required.");
    }

    try {
      const result = await this.contractService.submitInputToContract(
        submitObj
      );
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async GetSellOffersForSpecificUser(data) {
    const submitObj = {
      type: constants.RequestTypes.NFT,
      subType: constants.RequestSubTypes.GetSellOffersForSpecificUser,
      data: { filters: data },
    };

    // check whether all three fields on submitObj are present
    if (!submitObj.type || !submitObj.subType || !submitObj.data) {
      throw new Error("Invalid data. type, subType and data are required.");
    }

    try {
      const result = await this.contractService.submitInputToContract(
        submitObj
      );
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async getReservationDetailsForNFT(data) {
    const submitObj = {
      type: constants.RequestTypes.NFT,
      subType: constants.RequestSubTypes.GetReservationDetailsForNFT,
      data: { filters: data },
    };

    // check whether all three fields on submitObj are present
    if (!submitObj.type || !submitObj.subType || !submitObj.data) {
      throw new Error("Invalid data. type, subType and data are required.");
    }

    try {
      const result = await this.contractService.submitReadRequest(submitObj);
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async acceptSellOfferForSpecificUser(data) {
    const submitObj = {
      type: constants.RequestTypes.NFT,
      subType: constants.RequestSubTypes.AcceptSellOfferForSpecificUser,
      data: data,
    };

    // check whether all three fields on submitObj are present
    if (!submitObj.type || !submitObj.subType) {
      throw new Error("Invalid data. type, subType and data are required.");
    }

    try {
      const result = await this.contractService.submitInputToContract(
        submitObj
      );
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async acceptSellOfferForCommonUser(data) {
    const submitObj = {
      type: constants.RequestTypes.NFT,
      subType: constants.RequestSubTypes.AcceptSellOfferForCommonUser,
      data: data,
    };

    // check whether all three fields on submitObj are present
    if (!submitObj.type || !submitObj.subType) {
      throw new Error("Invalid data. type, subType and data are required.");
    }

    try {
      const result = await this.contractService.submitInputToContract(
        submitObj
      );
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async addSellOfferDataWithoutDestination(data) {
    const submitObj = {
      type: constants.RequestTypes.NFT,
      subType: constants.RequestSubTypes.AddSellOfferDataWithoutDestination,
      data: data,
    };
    console.log("submitObj", submitObj);

    // check whether all three fields on submitObj are present
    if (!submitObj.type || !submitObj.subType) {
      throw new Error("Invalid data. type, subType and data are required.");
    }

    try {
      const result = await this.contractService.submitInputToContract(
        submitObj
      );
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async getSellOffersForAllUsers() {
    const submitObj = {
      type: constants.RequestTypes.NFT,
      subType: constants.RequestSubTypes.GetSellOffersForAllUsers,
    };

    // check whether all three fields on submitObj are present
    if (!submitObj.type || !submitObj.subType) {
      throw new Error("Invalid data. type, subType and data are required.");
    }

    try {
      const result = await this.contractService.submitReadRequest(submitObj);
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async getSellOffersCreatedByUser(data) {
    const submitObj = {
      type: constants.RequestTypes.NFT,
      subType: constants.RequestSubTypes.GetSellOffersCreatedByUser,
      data: { filters: data },
    };

    // check whether all three fields on submitObj are present
    if (!submitObj.type || !submitObj.subType) {
      throw new Error("Invalid data. type, subType and data are required.");
    }

    try {
      const result = await this.contractService.submitReadRequest(submitObj);
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async removeSellOffer(data) {
    const submitObj = {
      type: constants.RequestTypes.NFT,
      subType: constants.RequestSubTypes.RemoveSellOffer,
      data: data,
    };

    // check whether all three fields on submitObj are present
    if (!submitObj.type || !submitObj.subType) {
      throw new Error("Invalid data. type, subType and data are required.");
    }

    try {
      const result = await this.contractService.submitInputToContract(
        submitObj
      );
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async getNFTsForSpecificUser(data) {
    const submitObj = {
      type: constants.RequestTypes.NFT,
      subType: constants.RequestSubTypes.GetNFTsForSpecificUser,
      data: { filters: data },
    };

    // check whether all three fields on submitObj are present
    if (!submitObj.type || !submitObj.subType || !submitObj.data) {
      throw new Error("Invalid data. type, subType and data are required.");
    }

    try {
      const result = await this.contractService.submitReadRequest(submitObj);
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async removeNFTandSellOffersWhenReservationCancelled(data) {
    const submitObj = {
      type: constants.RequestTypes.NFT,
      subType:
        constants.RequestSubTypes
          .RemoveNFTandSellOffersWhenReservationCancelled,
      data: data,
    };

    // check whether all three fields on submitObj are present
    if (!submitObj.type || !submitObj.subType) {
      throw new Error("Invalid data. type, subType and data are required.");
    }

    try {
      const result = await this.contractService.submitInputToContract(
        submitObj
      );
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async checkAlreadyOwnershipTransferCreated(data) {
    const submitObj = {
      type: constants.RequestTypes.NFT,
      subType: constants.RequestSubTypes.CheckAlreadyOwnershipTransferCreated,
      data: { filters: data },
    };

    // check whether all three fields on submitObj are present
    if (!submitObj.type || !submitObj.subType) {
      throw new Error("Invalid data. type, subType and data are required.");
    }

    try {
      const result = await this.contractService.submitReadRequest(submitObj);
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
}
