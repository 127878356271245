import React, { useEffect, useState } from "react";
import "./NFTList.scss";
import { getAllAccountNFTs } from "../../../services-common/xumm-api-service";
import { LocalStorageKeys } from "../../../constants/constants";
import { useDispatch } from "react-redux";
import { setShowScreenLoader } from "../../../redux/screenLoader/ScreenLoaderSlice";
import NFTItem from "../NFTItem/NFTItem";
import { FaExclamationCircle } from "react-icons/fa";
import { getCleanedDataForNFT } from "../../../helpers/getCleanedDataForNFT";
import NFTService from "../../../services-domain/nft-service";

const NFTList = () => {
  const nftService = new NFTService();
  const userWalletAddress = localStorage.getItem(
    LocalStorageKeys.AccountAddress
  );
  const [userNFTs, setUserNFTs] = useState([]);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const getUserNFTs = async () => {
      try {
        dispatch(setShowScreenLoader(true));
        setError(false);
        // const response = await getAllAccountNFTs(userWalletAddress);
        const response = await nftService.getNFTsForSpecificUser({
          owner: userWalletAddress,
        });
        const cleanedData = getCleanedDataForNFT(response);
        setUserNFTs(cleanedData);
      } catch (err) {
        setError(true);
        console.log("Error getting user NFTs", err);
      } finally {
        dispatch(setShowScreenLoader(false));
      }
    };
    getUserNFTs();
  }, [userWalletAddress, dispatch]);

  return (
    <div className="nft-list-container">
      {error ? (
        <div className="error-container">
          <h2>Error fetching NFTs</h2>
          <p>
            Something went wrong while fetching your NFTs. Please try again
            later.
          </p>
        </div>
      ) : (
        <>
          {userNFTs.length > 0 ? (
            <div className="nft-list">
              {userNFTs.map((nft, index) => (
                <NFTItem key={index} nft={nft} />
              ))}
            </div>
          ) : (
            <div className="no-nft-container">
              <div className="no-nft-sub-container">
                <div>
                  <FaExclamationCircle size={40} className="no-nft-icon" />
                  <h3 className="no-nft-title">
                    No NFTs available at the moment
                  </h3>
                </div>
                <span>Add reservations to create NFT</span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NFTList;
