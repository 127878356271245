const RequestTypes = {
  HOTEL: "Hotel",
  ROOM: "Room",
  CUSTOMER: "Customer",
  RESERVATION: "Reservation",
  USER: "User",
  NFT: "NFT",
};

const RequestSubTypes = {
  REGISTER_HOTEL: "RegisterHotel",
  GET_USER_BY_WALLET_ADDRESS: "GetUserByWalletAddress",
  REGISTER_USER: "RegisterUser",
  REQUEST_TOKEN_OFFER: "RequestTokenOffer",
  REGISTRATION_CONFIRMATION: "RegistrationConfirmation",
  GET_HOTEL_BY_ID: "GetHotelById",
  DEREG_HOTEL: "DeregHotel",
  RATE_HOTEL: "RateHotel",
  IS_REGISTERED_HOTEL: "IsRegisteredHotel",
  SEARCH_HOTELS_WITH_ROOM: "SearchHotelsWithRooms",
  GET_SINGLE_HOTEL_WITH_ROOMS: "GetSingleHotelWithRooms",
  GET_HOTELS_BY_LOGGED_IN_ADDRESS: "GetHotelsListByLoggedInAddresss",
  GET_HOTEL_IMAGES_BY_ID: "GetHotelImagesById",
  GET_AVAILABLE_ROOM_COUNT: "GetAvailableRoomCount",
  GET_RECENT_HOTEL: "GetRecentHotels",
  GET_HOTELS_LIST_MAPPED_WITH_AI_SEARCH: "GetHotelsListMappedWithAISearch",

  GET_ROOMS: "GetRooms",
  GET_ROOMS_BY_HOTELID: "GetRoomTypes",
  GET_ROOMTYPE_BY_ID: "GetRoomTypeById",
  CREATE_ROOMTYPE: "CreateRoomType",
  EDIT_ROOM: "EditRoom",
  DELETE_ROOM: "DeleteRoom",

  CREATE_CUSTOMER: "CreateCustomer",
  EDIT_CUSTOMER: "EditCustomer",
  DELETE_CUSTOMER: "DeleteCustomer",
  GET_CUSTOMERS: "GetCustomers",

  MAKE_RESERVATIONS: "MakeReservations",
  GET_RESERVATIONS: "GetReservations",
  DELETE_RESERVATION: "DeleteReservation",
  CANCEL_RESERVATION: "CancelReservation",
  GET_PENDING_REFUND_RESERVATIONS: "GetPendingRefundReservations",
  UPDATE_RESERVATION_STATUS: "UpdateReservationStatus",
  GET_RESERVATIONS_By_Hotel_Owner_Wallet_Address:
    "GetReservationsByHotelOwnerWalletAddress",

  AddNFTData: "AddNFTData",
  GetAvailableNFTs: "GetAvailableNFTs",
  GetOneNFTData: "GetOneNFTData",
  AddSellOfferDataWithDestination: "AddSellOfferDataWithDestination",
  AddSellOfferDataWithoutDestination: "AddSellOfferDataWithoutDestination",
  GetSellOffersForAllUsers: "GetSellOffersForAllUsers",
  GetSellOffersForSpecificUser: "GetSellOffersForSpecificUser",
  AcceptSellOfferForSpecificUser: "AcceptSellOfferForSpecificUser",
  AcceptSellOfferForCommonUser: "AcceptSellOfferForCommonUser",
  GetReservationDetailsForNFT: "GetReservationDetailsForNFT",
  GetSellOffersCreatedByUser: "GetSellOffersCreatedByUser",
  RemoveSellOffer: "RemoveSellOffer",
  GetNFTsForSpecificUser: "GetNFTsForSpecificUser",
  RemoveNFTandSellOffersWhenReservationCancelled:
    "RemoveNFTandSellOffersWhenReservationCancelled",
  CheckAlreadyOwnershipTransferCreated: "CheckAlreadyOwnershipTransferCreated",
};

const FacilityStatuses = {
  AVAILABLE: "Available",
  UNAVAILABLE: "UnAvaialble",
};

module.exports = {
  RequestTypes,
  RequestSubTypes,
  FacilityStatuses,
};
