import { Col, Row, Label, Button } from "reactstrap";
import Card1 from "../../layout/Card";
import {
  LocalStorageKeys,
  LoginMethods,
  PaymentResults,
  ReservationWaitingTime,
  TransactionTypes,
} from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import {
  initWeb3Auth,
  getBalance,
  sendTransaction,
} from "../../services-common/web3auth-service";
import { setShowScreenLoader } from "../../redux/screenLoader/ScreenLoaderSlice";
import { store } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import PaymentSelectionTopUpSocial from "../PaymentSelectionTopUpSocial/PaymentSelectionTopUpSocial";
import {
  openPaymentSelectionTopUpSocial,
  closePaymentSelectionTopUpSocial,
} from "../../redux/PaymentSelectionTopUpSocial/PaymentSelectionTopUpSocialSlice";

const PaymentSelection = ({ setSelectedGateway, handleMakeReservation }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let selectionDetails = useSelector((state) => state.selectionDetails.value);
  const { isPaymentSelectionTopUpSocialOpen } = useSelector(
    (store) => store.paymentSelectionTopUpSocial
  );
  const proceedToPayWithStripe = () => {
    setSelectedGateway("Stripe");
  };

  const proceedToPayWithCoingate = () => {
    setSelectedGateway("Coingate");
  };

  const proceedToPayWithDigitalCurrency = async () => {
    const sourceAddress = localStorage.getItem(LocalStorageKeys.AccountAddress);
    const selectionData = JSON.parse(
      localStorage.getItem(LocalStorageKeys.HotelSelectionDetails)
    );
    const roomTypes = selectionData.RoomTypes;

    // Calculate total price for room selection
    let totalPrice = 0;
    roomTypes.forEach((roomType) => {
      totalPrice += roomType.count * roomType.roomData.Price;
    });

    const checkInDate = new Date(selectionData.CheckIn);
    const checkOutDate = new Date(selectionData.CheckOut);

    if (!sourceAddress) {
      navigate("/signin");
    } else {
      dispatch(setShowScreenLoader(true));
      const isPayWithInternalWallet =
        localStorage.getItem(LocalStorageKeys.PayWithInternalWallet) === "true";
      if (!isPayWithInternalWallet) {
        await initWeb3Auth(LoginMethods.XRPL);
      }

      const balance = await getBalance(sourceAddress);

      // Send transactions
      if (balance - 10 >= totalPrice) {
        // set payment type
        const transactionType = TransactionTypes.ESCROW_CREATE;
        const today = new Date();
        let finishAfterTime = new Date(
          new Date(checkInDate).setDate(new Date(checkInDate).getDate() - 7)
        );
        // const finishAfterTime = new Date(Date.now() + 1 * 60 * 1000);
        const cancelAfterTime = new Date(checkOutDate);
        // const cancelAfterTime = new Date(Date.now() + 2 * 60 * 1000);

        if (finishAfterTime < today) {
          finishAfterTime = new Date(Date.now() + ReservationWaitingTime);
        }

        try {
          const response = await sendTransaction(
            sourceAddress,
            selectionData.WalletAddress,
            parseFloat(totalPrice).toFixed(6),
            finishAfterTime,
            cancelAfterTime,
            transactionType
          );

          console.log("======================================");
          console.log("Send Transaction Response");
          console.log(response);
          console.log("======================================");

          if (response.transactionResult === PaymentResults.tesSUCCESS) {
            await handleMakeReservation(response);
            dispatch(setShowScreenLoader(false));
            toast.success("Reservation successful!");
            localStorage.removeItem(LocalStorageKeys.HotelSelectionDetails);
            navigate("/my-reservations");
          } else if (
            response.transactionResult === PaymentResults.tecUNFUNDED
          ) {
            toast.error(
              "Transaction failed. Not enough balance to make transaction. Please recharge your account and try again"
            );
            dispatch(setShowScreenLoader(false));
          } else if (response.transactionResult === PaymentResults.tecNO_DST) {
            dispatch(setShowScreenLoader(false));
            toast.error("Error: Destination doesn't exists!");
          } else if (
            response.transactionResult === PaymentResults.tecNO_PERMISSION
          ) {
            dispatch(setShowScreenLoader(false));
            toast.error("Error: No permission to send transaction!");
          } else {
            toast.error("Transaction failed. Please try again!");
            dispatch(setShowScreenLoader(false));
          }
        } catch (err) {
          console.log("Error in proceedToPayWithDigitalCurrency:", err);
        }
      } else {
        toast.error("Insufficient account balance to make a reservation!");
        dispatch(setShowScreenLoader(false));
      }
    }
  };

  const proceedToPayWithInternalWallet = async () => {
    dispatch(setShowScreenLoader(true));
    localStorage.setItem(LocalStorageKeys.PayWithInternalWallet, "true");
    try {
      await proceedToPayWithDigitalCurrency();
    } catch (err) {
      console.log("Error in proceedToPayWithInternalWallet:", err);
    } finally {
      dispatch(setShowScreenLoader(false));
      localStorage.removeItem(LocalStorageKeys.PayWithInternalWallet);
    }
  };

  const handleOpenTopUpModal = () => {
    dispatch(openPaymentSelectionTopUpSocial());
  };

  const closeModal = () => {
    dispatch(closePaymentSelectionTopUpSocial());
  };

  return (
    <>
      <Card1>
        <Row>
          <Col md={12}>
            <Label for="firstName">Please select a payment method?</Label>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 25,
              }}
            >
              <Button
                className="secondaryButton"
                style={{ width: "250px" }}
                onClick={() => proceedToPayWithStripe()}
              >
                Pay with Credit/Debit Card
              </Button>
            </div>
          </Col>
          <Col md={6}>
            {localStorage.getItem(LocalStorageKeys.LoginMethod) !==
              LoginMethods.SOCIAL && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: 25,
                }}
              >
                <Button
                  className="secondaryButton"
                  style={{ width: "250px" }}
                  onClick={() => proceedToPayWithDigitalCurrency()}
                >
                  Pay with Digital Currency
                </Button>
              </div>
            )}
            {localStorage.getItem(LocalStorageKeys.LoginMethod) ===
              LoginMethods.SOCIAL && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: 25,
                }}
              >
                <Button
                  className="secondaryButton"
                  style={{ width: "250px" }}
                  onClick={handleOpenTopUpModal}
                >
                  Pay with Digital Currency
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Card1>
      {isPaymentSelectionTopUpSocialOpen && (
        <PaymentSelectionTopUpSocial
          onClose={closeModal}
          proceedToPayWithInternalWallet={proceedToPayWithInternalWallet}
          proceedToPayWithDigitalCurrency={proceedToPayWithDigitalCurrency}
        />
      )}
    </>
  );
};

export default PaymentSelection;
