import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import "./NFTItem.scss";
import { Link } from "react-router-dom";
import NFTActionButtons from "../NFTActionButtons/NFTActionButtons";
import { Image } from "react-bootstrap";

const NFTItem = ({ nft, handleAcceptance = null, handleClearence = null }) => {
  const [showReservationDetails, setShowReservationDetails] = useState(false);
  const [showHotelDetails, setShowHotelDetails] = useState(false);
  const [showRoomTypeDetails, setShowRoomTypeDetails] = useState(false);

  const toggleReservationDetails = () => {
    setShowReservationDetails((prev) => !prev);
  };

  const toggleHotelDetails = () => {
    setShowHotelDetails((prev) => !prev);
  };

  const toggleRoomTypeDetails = () => {
    setShowRoomTypeDetails((prev) => !prev);
  };

  return (
    <div className="nft-item">
      {/* {nft?.decodedURI && (
        <div className="nft-info">
          <p>
            <Link to={nft?.decodedURI} className="nft-link">
              Go To NFT
            </Link>
          </p>
        </div>
      )} */}
      <div className="hotel-image-container">
        {nft?.hotelImages?.length > 0 && nft?.hotelImages[0] && (
          <div className="image-wrapper">
            <Image
              src={nft?.hotelImages[0]}
              alt={"Hotel image"}
              className="main-image"
              fluid
            />
          </div>
        )}
        <div className="image-grid">
          {nft?.hotelImages?.length > 0 && nft?.hotelImages[1] && (
            <Image
              src={nft?.hotelImages[1]}
              alt={"Hotel image"}
              className="grid-image"
              fluid
            />
          )}
          {nft?.hotelImages?.length > 0 && nft?.hotelImages[2] && (
            <Image
              src={nft?.hotelImages[2]}
              alt={"Hotel image"}
              className="grid-image"
              fluid
            />
          )}
        </div>
      </div>

      <button className="details-toggle" onClick={toggleReservationDetails}>
        <div>Reservation Details</div>
        <IoIosArrowDown
          className={`toggle-icon ${showReservationDetails ? "rotated" : ""}`}
        />
      </button>
      {showReservationDetails && (
        <div className="nft-details">
          <div className="details-row-code">
            <p className="details-row-p-code">Reservation Code</p>
            <span className="details-row-span-code">
              {nft?.reservationDetails?.ReservationCode}
            </span>
          </div>
          <div className="details-row">
            <p className="details-row-p">Price</p>
            <span className="details-row-span">
              {nft?.reservationDetails?.Price} XRP
            </span>
          </div>
          <div className="details-row">
            <p className="details-row-p">From Date</p>
            <span className="details-row-span">
              {nft?.reservationDetails?.FromDate}
            </span>
          </div>
          <div className="details-row">
            <p className="details-row-p">To Date</p>
            <span className="details-row-span">
              {nft?.reservationDetails?.ToDate}
            </span>
          </div>
          <div className="details-row">
            <p className="details-row-p">Nights</p>
            <span className="details-row-span">
              {nft?.reservationDetails?.NoOfNights}
            </span>
          </div>
        </div>
      )}

      <button className="details-toggle" onClick={toggleHotelDetails}>
        <div>Hotel Details</div>
        <IoIosArrowDown
          className={`toggle-icon ${showHotelDetails ? "rotated" : ""}`}
        />
      </button>

      {showHotelDetails && (
        <div className="nft-details">
          <div className="details-row">
            <p className="details-row-p">Name:</p>
            <span className="details-row-span">{nft?.hotelDetails?.Name}</span>
          </div>
          <div className="details-row">
            <p className="details-row-p">Star Ratings:</p>
            <span className="details-row-span">
              {nft?.hotelDetails?.StarRatings}
            </span>
          </div>
          <div className="details-row-contact-details">
            <p>Contact Details</p>
            <div>
              <p>Owner</p>
              <span>{nft?.hotelDetails?.ContactDetails?.FullName}</span>
            </div>
            <div>
              <p>Email</p>
              <span>{nft?.hotelDetails?.ContactDetails?.Email}</span>
            </div>
            <div>
              <p>Phone</p>
              <span>{nft?.hotelDetails?.ContactDetails?.PhoneNumber}</span>
            </div>
          </div>
          <div className="details-row-contact-details">
            <p>Location</p>
            <address style={{ fontStyle: "italic" }}>
              {[
                nft?.hotelDetails?.Location?.AddressLine01,
                nft?.hotelDetails?.Location?.AddressLine02,
                nft?.hotelDetails?.Location?.City,
              ]
                .filter(Boolean)
                .join(", ")}
            </address>
          </div>
          <div className="details-row">
            <p className="details-row-p">Distance from city</p>
            <span className="details-row-span">
              {nft?.hotelDetails?.Location?.DistanceFromCity} km
            </span>
          </div>
          <div>
            <p className="facilities-p">Facilities</p>
            {nft?.hotelDetails?.Facilities?.map((facility, index) => (
              <>
                <div key={index} className="nft-details">
                  {facility?.Name}
                </div>
              </>
            ))}
          </div>
        </div>
      )}

      <button className="details-toggle" onClick={toggleRoomTypeDetails}>
        <div>Room Details</div>
        <IoIosArrowDown
          className={`toggle-icon ${showRoomTypeDetails ? "rotated" : ""}`}
        />
      </button>

      {showRoomTypeDetails && (
        <div className="nft-details">
          {nft?.roomTypeDetails?.map((room, index) => (
            <>
              <div className="details-row">
                <p className="details-row-p">Code</p>
                <span className="details-row-span">{room?.Code}</span>
              </div>
              <div className="details-row">
                <p className="details-row-p">Size (sq.ft)</p>
                <span className="details-row-span">{room?.Sqft}</span>
              </div>
              <div className="details-row">
                <p className="details-row-p">Single Beds</p>
                <span className="details-row-span">{room?.SingleBedCount}</span>
              </div>
              <div className="details-row">
                <p className="details-row-p">Double Beds</p>
                <span className="details-row-span">{room?.DoubleBedCount}</span>
              </div>
              <div className="details-row">
                <p className="details-row-p">Triple Beds</p>
                <span className="details-row-span">{room?.TripleBedCount}</span>
              </div>
              <div>
                <p className="facilities-p">Facilities</p>
                {room?.Facilities?.map((facility, index) => (
                  <>
                    <div key={index} className="nft-details">
                      {facility?.Name}
                    </div>
                  </>
                ))}
              </div>
            </>
          ))}
        </div>
      )}
      {nft?.offerPrice && (
        <div className="nft-details">
          <div className="details-row">
            <p className="details-row-p">Offer Price</p>
            <span className="details-row-span">
              {nft?.offerPrice === "NULL" ? 0 : nft?.offerPrice} XRP
            </span>
          </div>
        </div>
      )}

      <NFTActionButtons
        nft={nft}
        handleAcceptance={handleAcceptance}
        handleClearence={handleClearence}
      />
    </div>
  );
};

export default NFTItem;
