import { useEffect, useState } from "react";
import NFTService from "../../../services-domain/nft-service";
import "./SellOffersForUser.scss";
import { LocalStorageKeys } from "../../../constants/constants";
import { useDispatch } from "react-redux";
import { setShowScreenLoader } from "../../../redux/screenLoader/ScreenLoaderSlice";
import SellOfferForUser from "../SellOfferForUser/SellOfferForUser";
import { FaExclamationCircle } from "react-icons/fa";
import { getCleanedDataForNFT } from "../../../helpers/getCleanedDataForNFT";

const SellOffersForUser = () => {
  const nftService = new NFTService();
  const dispatch = useDispatch();
  const [offersForUser, setOffersForUser] = useState([]);
  const userWalletAddress = localStorage.getItem(
    LocalStorageKeys.AccountAddress
  );

  useEffect(() => {
    const getSellOffers = async () => {
      try {
        dispatch(setShowScreenLoader(true));
        const response = await nftService.GetSellOffersForSpecificUser({
          destination: userWalletAddress,
        });
        const cleanedData = getCleanedDataForNFT(response);
        setOffersForUser(cleanedData);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setShowScreenLoader(false));
      }
    };
    getSellOffers();
  }, []);

  const removeOffer = (offerId) => {
    setOffersForUser((prev) =>
      prev.filter((offer) => offer.offerId !== offerId)
    );
  };

  return (
    <div className="sell-offer-list-container">
      <>
        {offersForUser.length > 0 ? (
          <div className="sell-offers-list">
            {offersForUser.map((offer, index) => (
              <SellOfferForUser
                key={index}
                offer={offer}
                removeOffer={removeOffer}
              />
            ))}
          </div>
        ) : (
          <div className="no-fferrs-container">
            <div className="no-offers-sub-container">
              <FaExclamationCircle size={40} className="no-offers-icon" />
              <h3 className="no-offers-title">
                Oops! No offers for you at the moment
              </h3>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default SellOffersForUser;
