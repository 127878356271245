import "./usernft.scss";
import NFTList from "../../components/UserNFT/NFTList/NFTList";
import LayoutPageNFTMarketplace from "./LayoutPageNFTMarketplace";

const NFTListPage = () => {
  return (
    <LayoutPageNFTMarketplace>
      <NFTList />
    </LayoutPageNFTMarketplace>
  );
};

export default NFTListPage;
