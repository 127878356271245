import { useEffect, useState } from "react";
import "./UserDeals.scss";
import { LocalStorageKeys } from "../../../constants/constants";
import { useDispatch } from "react-redux";
import { setShowScreenLoader } from "../../../redux/screenLoader/ScreenLoaderSlice";
import NFTService from "../../../services-domain/nft-service";
import { FaExclamationCircle } from "react-icons/fa";
import { getCleanedDataForNFT } from "../../../helpers/getCleanedDataForNFT";
import DealCreatedUser from "../DealCreatedUser/DealCreatedUser";

const UserDealsNFT = () => {
  const userWalletAddress = localStorage.getItem(
    LocalStorageKeys.AccountAddress
  );
  const nftService = new NFTService();
  const dispatch = useDispatch();
  const [sellOffers, setSellOffers] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getUserNFTs = async () => {
      try {
        setError(false);
        dispatch(setShowScreenLoader(true));
        const response = await nftService.getSellOffersCreatedByUser({
          createdByUser: userWalletAddress,
        });
        console.log("Sell offers", response);
        const cleanedData = getCleanedDataForNFT(response);
        setSellOffers(cleanedData);
      } catch (err) {
        setError(true);
        console.log("Error getting user NFTs", err);
      } finally {
        dispatch(setShowScreenLoader(false));
      }
    };
    getUserNFTs();
  }, [userWalletAddress, dispatch]);

  const removeOffer = (offerId) => {
    setSellOffers((prev) => prev.filter((offer) => offer.offerId !== offerId));
  };

  return (
    <div className="nft-list-container">
      {error ? (
        <div className="error-container">
          <h2>Error fetching NFTs</h2>
          <p>
            Something went wrong while fetching your NFTs. Please try again
            later.
          </p>
        </div>
      ) : (
        <>
          {sellOffers.length > 0 ? (
            <div className="nft-list">
              {sellOffers.map((nft, index) => (
                <DealCreatedUser
                  key={index}
                  offer={nft}
                  removeOffer={removeOffer}
                />
              ))}
            </div>
          ) : (
            <div className="no-nft-container">
              <div className="no-nft-sub-container">
                <div>
                  <FaExclamationCircle size={40} className="no-nft-icon" />
                  <h3 className="no-nft-title">No sell offers created yet</h3>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UserDealsNFT;
