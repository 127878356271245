import "./styles.scss";
import Container from "react-bootstrap/Container";
import { Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect, useState } from "react";
import ReservationService from "../../services-domain/reservation-service";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import {
  LocalStorageKeys,
  LoginMethods,
  PaymentResults,
  ReservationStatus,
} from "../../constants/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FaInfoCircle } from "react-icons/fa";
import ConfirmationModal from "../../modals/ConfirmationModal/ConfirmationModal";
import toast from "react-hot-toast";
import { store } from "../../redux/store";
import { setShowScreenLoader } from "../../redux/screenLoader/ScreenLoaderSlice";
import { cancelEscrowForSocial } from "../../services-common/web3auth-service";
import NFTService from "../../services-domain/nft-service";

const ViewCustomerReservations = () => {
  const [reservationList, setReservationList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [reservationId, setReservationId] = useState(0);
  const [reservationStatus, setReservationStatus] = useState("");
  const [selectedRes, setSelectedRes] = useState(null);
  const nftService = new NFTService();
  const [dates, setDates] = useState({
    now: null,
    cancelAfterTime: null,
  });

  const _reservationService = new ReservationService();

  const getReservationList = async (filteringAddress) => {
    const reservationsRes = await _reservationService.getReservations(
      0,
      filteringAddress
    );
    return reservationsRes;
  };

  const openModal = (rs, now, cancelAfterTime) => {
    setReservationId(rs.Id);
    setDates({ now, cancelAfterTime });
    setSelectedRes(rs);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleCancel = async () => {
    closeModal();
    store.dispatch(setShowScreenLoader(true));

    if (selectedRes.IsInternalWallet) {
      if (selectedRes.Status === ReservationStatus.RESERVED) {
        try {
          const data = {
            ReservationId: reservationId,
            Status: ReservationStatus.CANCELLED_AND_PENDING_REFUND,
          };
          const result = await _reservationService.cancelReservation(data);
          setReservationStatus(ReservationStatus.CANCELLED_AND_PENDING_REFUND);

          if (result) {
            if (selectedRes?.ReservationCode) {
              const removeNFTres =
                await nftService.removeNFTandSellOffersWhenReservationCancelled(
                  {
                    reservationCode: selectedRes.ReservationCode,
                  }
                );
              console.log("removeNFTres", removeNFTres);
            }
            toast.success(
              "Reservation cancelled successfully. Your refund is on its way"
            );
          }
        } catch (error) {
          console.error("Error cancelling reservation:", error);
        } finally {
          store.dispatch(setShowScreenLoader(false));
        }
      } else if (
        selectedRes.Status ===
        (ReservationStatus.CANCELLED_AND_PENDING_REFUND ||
          ReservationStatus.REJECTED_AND_PENDING_REFUND)
      ) {
        const answer = await cancelEscrowForSocial(selectedRes);
        if (
          answer &&
          answer.result &&
          answer.result.engine_result === PaymentResults.tesSUCCESS
        ) {
          try {
            const data = {
              ReservationId: reservationId,
              Status: ReservationStatus.CANCELLED_AND_REFUNDED,
            };
            const result = await _reservationService.cancelReservation(data);
            console.log("reservation cancel result", result);
            if (result) {
              if (selectedRes?.ReservationCode) {
                const removeNFTres =
                  await nftService.removeNFTandSellOffersWhenReservationCancelled(
                    {
                      reservationCode: selectedRes.ReservationCode,
                    }
                  );
                console.log("removeNFTres", removeNFTres);
              }
              setReservationStatus(ReservationStatus.CANCELLED_AND_REFUNDED);
              toast.success(
                "Reservation cancelled successfully. Your amount has been refunded"
              );
            }
          } catch (err) {
            console.error("Error cancelling reservation:", err);
          } finally {
            store.dispatch(setShowScreenLoader(false));
          }
        } else {
          toast.error("Error cancelling reservation");
          console.error("Error cancelling reservation:", answer);
        }
        store.dispatch(setShowScreenLoader(false));
      }
    } else {
      try {
        const data = {
          ReservationId: reservationId,
          Status: ReservationStatus.CANCELLED_AND_PENDING_REFUND,
        };
        const result = await _reservationService.cancelReservation(data);
        setReservationStatus(ReservationStatus.CANCELLED_AND_PENDING_REFUND);

        if (result) {
          if (selectedRes?.ReservationCode) {
            const removeNFTres =
              await nftService.removeNFTandSellOffersWhenReservationCancelled({
                reservationCode: selectedRes.ReservationCode,
              });
            console.log("removeNFTres", removeNFTres);
          }
          toast.success(
            "Reservation cancelled successfully. Your refund is on its way"
          );
        }
      } catch (error) {
        console.error("Error cancelling reservation:", error);
      } finally {
        store.dispatch(setShowScreenLoader(false));
      }
    }
  };

  /* const handleCancel = async () => {
    try {
      closeModal();
      store.dispatch(setShowScreenLoader(true));

      const data = {
        ReservationId: reservationId,
        Status:
          selectedRes.IsInternalWallet && dates.now < dates.cancelAfterTime
            ? ReservationStatus.CANCELLED
            : ReservationStatus.REJECTED_AND_REFUNDED,
      };

      let result;

      if (selectedRes.IsInternalWallet) {
        if (dates.now < dates.cancelAfterTime) {
          result = await _reservationService.cancelReservation(data);
        } else {
          const answer = await cancelEscrowForSocial(selectedRes);
          if (answer?.result?.engine_result === PaymentResults.tesSUCCESS) {
            result = await _reservationService.cancelReservation(data);
          } else {
            throw new Error("Error cancelling reservation through escrow.");
          }
        }
      } else {
        result = await _reservationService.cancelReservation(data);
      }

      if (result) {
        setReservationStatus(data.Status);
        toast.success(
          "Reservation cancelled successfully. Your refund is on its way"
        );
      }
    } catch (error) {
      toast.error("Error cancelling reservation");
      console.error("Error cancelling reservation:", error);
    } finally {
      store.dispatch(setShowScreenLoader(false));
    }
  }; */

  useEffect(() => {
    let reservationListToBeUpdated = [];
    store.dispatch(setShowScreenLoader(true));
    getReservationList(localStorage.getItem(LocalStorageKeys.AccountAddress))
      .then(async (res) => {
        console.log("reservation list", res);
        if (res && res.length > 0) {
          for (const reservation of res) {
            if (
              reservation.Status === ReservationStatus.PENDING_RESERVATION &&
              new Date(reservation.FinishAfterTime) < new Date()
            ) {
              reservationListToBeUpdated.push(reservation.Id);
            }
          }
          if (reservationListToBeUpdated.length > 0) {
            const updateData = {
              reservationList: reservationListToBeUpdated,
              status: ReservationStatus.RESERVED,
            };

            await _reservationService.updateReservationStatus(updateData);
            getReservationList(
              localStorage.getItem(LocalStorageKeys.AccountAddress)
            ).then((updatedList) => {
              if (updatedList && updatedList.length > 0) {
                setReservationList(updatedList);
                store.dispatch(setShowScreenLoader(false));
              }
            });
          } else {
            setReservationList(res);
            store.dispatch(setShowScreenLoader(false));
          }
        } else {
          setReservationList([]);
          store.dispatch(setShowScreenLoader(false));
        }
      })
      .catch((e) => {
        console.log(e);
        store.dispatch(setShowScreenLoader(false));
      });
  }, [reservationStatus]);

  return (
    <>
      <Container style={{ minHeight: "45vh" }}>
        <Row>
          <Col lg={10}>
            <div
              className="page-header mt-4"
              style={{
                color: "rgb(44 44 118)",
                fontWeight: 700,
                fontSize: "50px",
                marginBottom: "20px",
              }}
            >
              My Reservations
            </div>
          </Col>
        </Row>
        {reservationList.length > 0 ? (
          <Row className="mt-3">
            <Col>
              <Table striped bordered>
                <thead>
                  <tr style={{ textAlign: "center" }}>
                    <th>Hotel</th>
                    <th>From</th>
                    <th>To</th>
                    <th>No. of nights</th>
                    <th>Room details</th>
                    <th>Price ({process.env.REACT_APP_CURRENCY})</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {reservationList.map((rs) => {
                    const now = new Date();
                    const cancelAfterTime = new Date(rs.CancelAfterTime);
                    const isInternalWallet = rs.IsInternalWallet === 1;

                    return (
                      <tr
                        key={rs.Id}
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        <td style={{ width: "18%" }}>{rs.HotelName}</td>
                        <td style={{ width: "10%" }}>{rs.FromDate}</td>
                        <td style={{ width: "10%" }}>{rs.ToDate}</td>
                        <td style={{ width: "10%" }}>{rs.NoOfNights}</td>

                        <td style={{ width: "18%" }}>
                          <Card
                            style={{
                              backgroundColor: "#ffffff00",
                              border: "solid #c4c4c4 1px",
                            }}
                          >
                            <Card.Body
                              style={{
                                backgroundColor: "#ffffff00",
                                padding: "5px",
                              }}
                            >
                              {rs.rooms.map((rm) => (
                                <Row key={rm.RoomTypeId}>
                                  <Col>
                                    {rm.Code} &nbsp;&nbsp;&nbsp;&nbsp;
                                    {rm.NoOfRooms}
                                  </Col>
                                </Row>
                              ))}
                            </Card.Body>
                          </Card>
                        </td>
                        <td style={{ width: "10%" }} title={`${rs.Price} XRP`}>
                          {(rs.Price * 115000).toFixed(2)}
                        </td>
                        <td style={{ width: "15%" }}>{rs.Status}</td>
                        <td style={{ width: "15%" }}>
                          {rs.WalletAddress === rs.DestinationAddress && (
                            <>
                              {isInternalWallet &&
                              (now < cancelAfterTime ||
                                rs.Status === ReservationStatus.RESERVED) ? (
                                <Button
                                  className="button-cancel"
                                  onClick={() =>
                                    openModal(rs, now, cancelAfterTime)
                                  }
                                  disabled={
                                    new Date(
                                      new Date(rs.FromDate).setDate(
                                        new Date(rs.FromDate).getDate() - 7
                                      )
                                    ) < new Date() ||
                                    rs.Status !== ReservationStatus.RESERVED
                                  }
                                  title="Cancel Reservation"
                                >
                                  <FontAwesomeIcon
                                    size="sm"
                                    icon={faTimes}
                                    className="fa fa-times"
                                    title="Cancel Reservation"
                                  />
                                </Button>
                              ) : isInternalWallet &&
                                now >= cancelAfterTime &&
                                rs.Status ===
                                  (ReservationStatus.CANCELLED_AND_PENDING_REFUND ||
                                    ReservationStatus.REJECTED_AND_REFUNDED ||
                                    ReservationStatus.CANCELLED_AND_REFUNDED) ? (
                                <button
                                  className="button-refund"
                                  onClick={() =>
                                    openModal(rs, now, cancelAfterTime)
                                  }
                                  title="Refund"
                                  disabled={
                                    rs.Status ===
                                    (ReservationStatus.REJECTED_AND_REFUNDED ||
                                      ReservationStatus.CANCELLED_AND_REFUNDED)
                                  }
                                >
                                  {rs.Status ===
                                  ReservationStatus.REJECTED_AND_REFUNDED
                                    ? "REFUNDED"
                                    : "REFUND"}
                                </button>
                              ) : (
                                <Button
                                  className="button-cancel"
                                  onClick={() =>
                                    openModal(rs, now, cancelAfterTime)
                                  }
                                  disabled={
                                    new Date(
                                      new Date(rs.FromDate).setDate(
                                        new Date(rs.FromDate).getDate() - 7
                                      )
                                    ) < new Date() ||
                                    rs.Status !== ReservationStatus.RESERVED
                                  }
                                >
                                  <FontAwesomeIcon
                                    size="sm"
                                    icon={faTimes}
                                    className="fa fa-times"
                                    title="Cancel Reservation"
                                  />
                                </Button>
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg={10}>
              <div
                className=" mt-4"
                style={{
                  color: "rgb(44 44 118)",
                  fontWeight: 500,
                  fontSize: "15px",
                }}
              >
                No reservations currently.
              </div>
            </Col>
          </Row>
        )}
        <ConfirmationModal
          show={showModal}
          title={"Please Confirm Reservation Cancellation"}
          handleClose={closeModal}
          handleCancel={handleCancel}
        />
      </Container>
    </>
  );
};

export default ViewCustomerReservations;
